import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import Seo from "../components/seo"

const AboutPage = () => (
  <Layout>
    <Seo title="About OC Beach Wax in Ocean City, MD" description="OC Beach Wax is located in Ocean City, MD, and is entirely dedicated to your waxing needs. Unlike a salon or spa, our focus is exclusively on a waxing experience that is clean, comfortable and fun." />
    <div className="row justify-content-center page-header">
        <div className="stripers">&nbsp;</div>
        <div className="col-11 col-md-10 col-lg-9">
            <h1>About OC Beach Wax in Ocean City, MD since 2014</h1>
            <p>We are wax experts dedicated entirely to <strong>proper body waxing</strong>. We take every precaution to maintain the highest levels of hygiene. We wouldn't dream of double dipping and gloves are always worn. Because most of what we do is waxing, our atmosphere is slightly different. We offer a <strong>modern, fun, clean, spa-like alternative</strong> to hair and nail salons as well as a less painful approach to waxing.</p>
            <p>
            OC Beach Wax is entirely dedicated to your waxing needs. Unlike a salon or spa, our focus is exclusively on a waxing experience that is clean, comfortable and fun. Our estheticians are professionally licensed and trained. We provide each customer with a personalized service that is above and beyond the "industry standard" to make sure each and every client is completely satisfied. We love what we do and look forward to the opportunity to show you. Call or book online for your waxing or skincare needs.
            </p>
            <p>
            Ryn Rohe opened OC Beach Wax in early July 2014. Prior to opening her new business, Rohe operated a mobile waxing service, Spa on the Go, in the Delmarva region for two years.
            </p>
        </div>        
    </div>  
  </Layout>
)

export default AboutPage
